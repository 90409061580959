.left-social-container {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 40px;
  right: auto;
  color: var(--chakra-colors-icon);
}

.left-social-stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style-type: none;
}

.left-social-link {
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 40px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  margin-bottom: 0px;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
}

.left-social-link:hover {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  color: #aeccec;
}

.left-social-hr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style-type: none;
}
