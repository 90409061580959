.right-social-container {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 40px;
  color: var(--chakra-colors-icon);
}



.right-social-link {
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  letter-spacing: 0.2em;
  font-size: var(--chakra-fontSizes-xs);
  padding-bottom: 40px;
}

.right-social-link:hover {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  color: #aeccec;
}

.right-social-hr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style-type: none;
}
