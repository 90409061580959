.list-style {
  list-style-type: none;
  overflow: auto;
  width: var(--chakra-sizes-full);
  padding-bottom: var(--chakra-space-2);
  padding-inline-start: var(--chakra-space-2);
  padding-inline-end: var(--chakra-space-2);
}

.list-style > :not(style) ~ :not(style) {
  margin-top: 0.5rem;
  margin-inline: 0px;
  margin-bottom: 0px;
}

.list-heading-text {
  color: var(--chakra-colors-gray-500);
  margin-top: var(--chakra-space-2);
  margin-bottom: var(--chakra-space-2);
  font-size: var(--chakra-fontSizes-xs);
  text-transform: capitalize;
}

.list-actionable-item {
  padding-inline-start: var(--chakra-space-3);
  padding-inline-end: var(--chakra-space-3);
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
  background: var(--chakra-colors-transparent);
  cursor: pointer;
  border-radius: var(--chakra-radii-md);
}

.actionable-item-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: row;
}

.actionable-item-text-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
}
